import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Seo from "../components/Seo"
import Layout from "../components/Layout"
import PageBody from "../components/PageBody"
import moment from "moment"
import Author from "../components/Blog/Author"
import styled from "styled-components"

import { MainBackground } from "../components/MainBackground"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container } from "../components/Container"
import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs"
import {Icon} from "../components/Icon";
import BlogCategories from "../components/Blog/BlogCategories";

const BlogTemplate = ({ data, location }) => {
  const [top, setTop] = useState(false);
  const {
    prismicBlog: { data: page, first_publication_date },
    allPrismicCategory: {edges: categoryList}
  } = data;
  const handleScroll = () => {
    if ((window.scrollY > 1500) && (!top)) {
      setTop(true);
    } else {
      setTop(false);
    }
  };
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  if (!page) return null
  const createdDate = moment(first_publication_date).format("ll")

  const {
    title,
    excerpt,
    author,
    category,
    featured_image: image,
    meta_title: metaTitle,
    meta_description: metaDescription,
    social_card: socialCard,
  } = page
  const fromUrl = new URLSearchParams(location?.search).get("from")

  const breadCrumbs = [
    { label: "Return to all blogs", linkUrl: fromUrl || "/blog" },
    {
      label: `${category?.document?.data?.name}`,
      linkUrl: `/category/${category?.document?.uid}`,
    },
  ]

  const bimage = getImage(image)
  let {
    document: {
      data: { name: categoryName },
    },
  } = category;
  return (
    <Layout location={location}>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
      </style>
      <Seo
        pathname={location.pathname}
        title={metaTitle || title.text}
        description={metaDescription || excerpt}
        image={
          socialCard.localFile
            ? socialCard.localFile.url
            : image
            ? image.url
            : undefined
        }
      />
      <MainBackground type={"violet"} container>
        <Container>
          <Wrapper>
            <BreadCrumbs list={breadCrumbs} />
            <div dangerouslySetInnerHTML={{ __html: title.html }}/>
            <BlogCategories categoryName={categoryName} data={categoryList}/>
            <Author author={author} />
          </Wrapper>
        </Container>
        <BlogContainer>
          {top && <TopBtn onClick={scrollTop}>
            <Icon type="SCROLL_TOP_BLOCK_WHITE"/>
          </TopBtn>}
          <Container variant="content">
            <GatsbyImage image={bimage} alt={'logo'} />
            <PageBody document={page} />
            <p>{createdDate}</p>
          </Container>
        </BlogContainer>
      </MainBackground>
    </Layout>
  )
}

BlogTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BlogTemplate

export const query = graphql`
  query BlogBySlug($uid: String!) {
    allPrismicCategory {
      edges {
        node {
          data {
            color
            name
          }
          uid
        }
      }
    }
    prismicBlog(uid: { eq: $uid }) {
      first_publication_date
      data {
        title {
          html
          text
        }
        excerpt
        featured_image {
          url
          gatsbyImageData(layout: FULL_WIDTH, width: 1200)
        }
        author {
          document {
            ... on PrismicPerson {
              uid
              type
              data {
                name
                title
                photo {
                  gatsbyImageData
                }
              }
            }
          }
        }
        category {
          document {
            ... on PrismicCategory {
              id
              uid
              data {
                name
                color
              }
            }
          }
        }
        meta_title
        meta_description
        social_card {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        body {
          ... on PrismicBlogDataBodyCta {
            id
            slice_type
            primary {
              action_label
              heading {
                html
              }
              subheading
              action_url {
                target
                url
                type
                uid
              }
            }
          }
          ... on PrismicBlogDataBodyTextBlock {
            id
            slice_type
            primary {
              heading {
                html
              }
              content {
                html
              }
            }
          }
          ... on PrismicBlogDataBodyImage {
            id
            slice_type
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              description
            }
          }
          ... on PrismicBlogDataBodyPositiveNegative {
            id
            slice_type
            primary {
              heading {
                html
              }
            }
            items {
              positive_title
              positive_content
              negative_title
              negative_content
            }
          }
        }
      }
    }
  }
`
const BlogContainer = styled.div`
  background: rgb(249, 249, 249);
  padding-bottom: 5px;
  padding-top: 20px;
  .gatsby-image-wrapper {
    display: block;
    max-width: 600px;
    margin: 0 auto 20px;
  }
  .block-img {
    display: block;
    max-width: 600px;
    margin: 0 auto 20px;
  }
  iframe {
    width: 100%;
    max-width: 600px;
    height: 350px;
    margin: 0 auto;
    display: block;
  }
  a[type="button"] {
    margin-bottom: 20px;
  }
  * {
    font-family: Roboto, sans-serif !important;
  }
  p {
    margin-bottom: 32px;
    margin-top: 0;
    color: rgb(62, 62, 62);
    font-weight: 500!important;
    font-size: 18px;
  }
  @media (max-width: 540px) {
    iframe {
      height: 300px;
    }
  }
  @media (max-width: 599px) {
    p {
      font-size: 15px;
      line-height: 25px;
      margin-bottom: 15px;
    }
    * {
      margin-bottom: 15px;
    }
  }
`
const TopBtn = styled.button`
  cursor: pointer;
  width: 55px;
  height: 55px;
  background: rgb(60,49,94);
  position: fixed;
  border-radius: 50%;
  bottom: 120px;
  right: 70px;
  z-index: 9999;
  :before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
  }
  @media (max-width: 1024px) {
    bottom: 100px;
    width: 45px;
    height: 45px;
    overflow: hidden;
    right: 30px;
    svg {
      position: absolute;
      top: -6px;
      left: -4px;
    }
  }
  @media (max-width: 768px) {
    bottom: 30px;
  }
`

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 20px;

  h1 {
    color: #fff;
    font-size: 39px;
    text-transform: uppercase;
    line-height: calc(78 / 64);
    margin-bottom: 0;
    @media (max-width: 992px) {
      max-width: 100%;
    }
    @media (max-width: 599px) {
      font-size: 25px;
      line-height: 28px;
    }
  }
  p {
    a {
      text-decoration: underline;
      transition: 0.3s;
      &:hover {
        color: ${({ theme }) => theme.colors.primary_color};
      }
    }
  }
`
