import React from "react"

import styled from "styled-components"
import { Link } from "gatsby"

const Author = ({ author }) => {
  return (<AuthorWrap>
    <div>
      <Box>
        <h5>Written by:</h5>
        <Link to={`/team/${author?.document?.uid}`}>{author?.document?.data?.name}</Link>
      </Box>
    </div>
  </AuthorWrap>)
}

export default Author

const AuthorWrap = styled.div`
  list-style: none;
  padding: 0;
  li {
    margin-bottom: 16px;
    p {
      margin: 0;
    }
  }
`

const Box = styled.div`
  -moz-box-flex: 1;
  flex-grow: 1;
  color: #3c3c3b;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  h5 {
    font-size: 20px;
    line-height: calc(38.4 / 24);
    color: #fff;
    font-weight: 600;
    margin-right: 10px;
  }
  p {
    font-size: 16px;
    line-height: calc(32 / 20);
    margin: 0 0 8px;
    color: #fff;
  }
  a {
    color: #2a95bf;
    font-size: 20px;
    line-height: calc(38 / 24);
    text-decoration: underline;
    font-weight: 500;
  }
  @media (max-width: 768px) {
    a {
      font-size: 18px;
    }
  }
`
